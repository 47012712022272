import React from 'react';

import { Principal } from './Principal.js';
import { readJWT } from './jwt.js';

export type PrincipalProviderState = {
  principal: Principal | null;
  updatePrincipal: () => void;
};
const PrincipalContext = React.createContext<PrincipalProviderState | undefined>(undefined);
PrincipalContext.displayName = 'Principal';
export const PRINCIPAL_UPDATED_EVENT = 'wild-principalUpdated';

export const PrincipalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [principal, setPrincipal] = React.useState<Principal | null>(getPrincipal());

  const updatePrincipal = () => {
    setPrincipal(getPrincipal());
  };

  window.addEventListener(PRINCIPAL_UPDATED_EVENT, updatePrincipal);

  return (
    <PrincipalContext.Provider value={{ principal, updatePrincipal }}>
      {children}
    </PrincipalContext.Provider>
  );
};

export const usePrincipal = () => {
  const principal = React.useContext(PrincipalContext);
  if (typeof principal === 'undefined') {
    throw new Error('usePrincipal must be used in the context of a <PrincipalProvider>');
  }
  return principal.principal;
};

export const useUpdatePrincipal = () => {
  const principal = React.useContext(PrincipalContext);
  if (typeof principal === 'undefined') {
    throw new Error('usePrincipal must be used in the context of a <PrincipalProvider>');
  }
  return principal.updatePrincipal;
};

export const getPrincipal = (storage = window.localStorage): Principal | null => {
  const token = getToken(storage);
  return token ? readJWT(token) : null;
};

export const getToken = (storage = window.localStorage) =>
  new URLSearchParams(window.location.search).get('t') || storage.getItem(TOKEN_STORAGE_KEY);

export const setPrincipalToken = (token: string | null, storage = window.localStorage) => {
  if (token) {
    storage.setItem(TOKEN_STORAGE_KEY, token);
  } else {
    storage.removeItem(TOKEN_STORAGE_KEY);
  }
};

const TOKEN_STORAGE_KEY = 'token';
