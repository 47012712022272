import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { FormField, ValidationMessageRenderer } from '@wildidea/components/FormField/FormField';
import { Modal } from '@wildidea/components/Modal/Modal';
import { CurrentPasswordField } from '@wildidea/components/Password/CurrentPasswordField';
import { useToaster } from '@wildidea/components/Toaster/Toaster';
import { useFormSubmitHandler } from '@wildidea/components/hooks/useFormSubmitHandler';
import { SubmitButton } from '@wildidea/components/Button/SubmitButton';

import { setPrincipalToken, useUpdatePrincipal } from '@app/Principal/usePrincipal.js';

import css from './LoginModal.module.css';

export interface LoginModalProps {
  defaultUsername: string | undefined;
  open: boolean;
  onClose: () => void;
}

export const LoginModal: React.FC<LoginModalProps> = ({ open, defaultUsername, onClose }) => {
  // TODO actually implement this
  const loginMutation = {
    mutateAsync: (_params: {}) => {
      throw new Error('Login is a stub');
      // @ts-ignore
      return Promise.resolve({ login: { token: 'logged-in' } });
    }
  };
  const updatePrincipal = useUpdatePrincipal();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const addToast = useToaster();

  const { onSubmit, promise } = useFormSubmitHandler(async (formEl) => {
    const formData = new FormData(formEl);
    const values = {
      emailAddress: formData.get('emailAddress') as string,
      password: formData.get('password') as string
    };

    try {
      setPrincipalToken((await loginMutation.mutateAsync({ input: values })).login.token);
      updatePrincipal();
      window.setTimeout(() => {
        if (pathname === '/signup') {
          navigate('/');
        }
      });
      formEl.reset();
      onClose();
    } catch (error) {
      addToast({
        id: LOGIN_TOAST_ID,
        type: 'error',
        timeout: true,
        header: 'Invalid Credentials',
        body: <>Your username or password were incorrect.</>
      });
      throw error;
    }
  });

  return (
    <Modal
      appearance="mobile"
      open={open}
      onClose={onClose}
      header="Log In"
      size="sm"
      color="paper2"
    >
      <form noValidate onSubmit={onSubmit}>
        <FormField
          label="Email Address"
          validationMessage={React.useCallback<ValidationMessageRenderer>(
            ({ valueMissing, typeMismatch, customError }, validationMessage) =>
              customError
                ? validationMessage
                : valueMissing || typeMismatch
                  ? 'Please provide a valid email address.'
                  : validationMessage,
            []
          )}
        >
          <input
            name="emailAddress"
            type="email"
            defaultValue={defaultUsername}
            required
            autoComplete="username"
          />
        </FormField>
        <CurrentPasswordField label="Password" name="password" />
        <SubmitButton block promise={promise}>
          Log In
        </SubmitButton>
        <Link
          to={{ search: `${search ? `${search}&` : '?'}forgotPassword` }}
          className={css.forgotPassword}
        >
          Forgot Password?
        </Link>
      </form>
    </Modal>
  );
};

const LOGIN_TOAST_ID = 'LOGIN_TOAST';
