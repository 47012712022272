import React from 'react';
// import 'mapbox-gl/dist/mapbox-gl.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Toaster } from '@wildidea/components/Toaster/Toaster';
import { DeviceLocationProvider } from '@wildidea/components/hooks/useDeviceLocation';
import { BackStackProvider } from '@wildidea/components/hooks/useBackStack';

import { MapTheme } from '@wildidea/maps/core/MapTheme';

import CommutablyTheme from '@theme/index.js';
import { MAP_STYLES } from './map.js';

import { App } from './App.js';
import { PrincipalProvider } from './Principal/usePrincipal.js';

let mswPromise: Promise<unknown> = Promise.resolve();
if (process?.env?.NODE_ENV === 'development') {
  import('./__dev.js').then(({ default: devApi }) => {
    Object.assign((window.$dev = window.$dev || {}), devApi);

    if (process.env.ENABLE_MOCKS === 'true' || window.sessionStorage.getItem('__enableMocking')) {
      mswPromise = devApi.enableMocking();
    } else {
      console.info(
        'Mocks are disabled. Make sure you are running against a real backend or setting up mocks with cypress.'
      );
      console.info(
        'To enable mocking, use $dev.enableMocking(). To enable mocking for the lifetime of this tab, use $dev.enableMocking(true).'
      );
    }
  });
}

mswPromise.then(() => {
  createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <BrowserRouter>
        <BackStackProvider>
          <DeviceLocationProvider>
            <PrincipalProvider>
              <CommutablyTheme>
                <MapTheme scheme="light" styles={MAP_STYLES} storeBaseLayer>
                  <Toaster defaultDismissTimeout={10000}>
                    <App />
                  </Toaster>
                </MapTheme>
              </CommutablyTheme>
            </PrincipalProvider>
          </DeviceLocationProvider>
        </BackStackProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
});
