import { MapStyles } from '@wildidea/maps/core/MapTheme';

export const MAP_STYLES: MapStyles<'Minimal' | 'Color' | 'Traffic'> = {
  Minimal: {
    light: 'mapbox://styles/mapbox/light-v10',
    dark: 'mapbox://styles/mapbox/dark-v10'
  },
  Color: {
    light: 'mapbox://styles/mapbox/streets-v12',
    dark: 'mapbox://styles/mapbox/navigation-guidance-night-v4'
  },
  Traffic: {
    light: 'mapbox://styles/mapbox/navigation-preview-day-v4',
    dark: 'mapbox://styles/mapbox/navigation-preview-night-v4'
  }
};
