import React from 'react';
import { config } from '@react-spring/web';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faCircle,
  faCircleDot,
  faLightbulbCflOn,
  faSquare
} from '@fortawesome/pro-light-svg-icons';
import { faClose, faArrowLeft } from '@fortawesome/pro-light-svg-icons';

import { Theme } from '@wildidea/components/Theme/Theme';

import './variables.css';
import './reset.css';
import './typography.css';

import './components/Button.css';
import './components/Card.css';
import './components/Checkbox.css';
import './components/ControlsRow.css';
import './components/Fieldset.css';
import './components/FileInputArea.css';
import './components/FormField.css';
import './components/Modal.css';
import './components/Pill.css';
import './components/RadioInput.css';
import './components/Small.css';
import './components/Strong.css';
import './components/SubmitButton.css';
import './components/Toaster.css';
import './components/Tooltip.css';

export const MOBILE_MODAL_TRANSITION = {
  from: {
    transform: 'translate3d(100vw, 0vh, 0) scale(1)'
  },
  enter: {
    transform: 'translate3d(0vw, 0vh, 0) scale(1)'
  },
  leave: {
    config: config.stiff,
    transform: 'translate3d(100vw, 0vh, 0) scale(1)'
  }
};

export const DESKTOP_MODAL_TRANSITION = {
  from: {
    transform: 'translate3d(0vw, 40vh, 0) scale(0.6)',
    opacity: 0
  },
  enter: {
    transform: 'translate3d(0vw, 0vh, 0) scale(1)',
    opacity: 1
  },
  leave: {
    config: config.stiff,
    transform: 'translate3d(0vw, 50vh, 0) scale(0.6)',
    opacity: 0
  }
};

const CommutablyTheme: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Theme
      className="commutably"
      icons={{
        Checkbox: {
          checked: (
            <FontAwesomeIcon
              icon={faCheckSquare}
              style={{ color: 'var(--color-blue-1)' }}
              size="lg"
            />
          ),
          unchecked: (
            <FontAwesomeIcon icon={faSquare} style={{ color: 'var(--color-grey-2)' }} size="lg" />
          )
        },
        RadioInput: {
          checked: (
            <FontAwesomeIcon
              icon={faCircleDot}
              style={{ color: 'var(--color-blue-1)' }}
              size="lg"
            />
          ),
          unchecked: (
            <FontAwesomeIcon icon={faCircle} style={{ color: 'var(--color-grey-2)' }} size="lg" />
          )
        },
        Alert: {
          info: <FontAwesomeIcon icon={faLightbulbCflOn} size="2x" />
        },
        Modal: {
          close: (
            <FontAwesomeIcon icon={faClose} size="xl" style={{ color: 'var(--color-purple-1)' }} />
          ),
          mobileClose: (
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="lg"
              style={{ color: 'var(--color-purple-1)', marginBottom: '-0.1em' }}
            />
          )
        }
      }}
      animations={{
        Common: undefined,
        Toaster: undefined,
        Modal: {
          window: DESKTOP_MODAL_TRANSITION,
          mobile: MOBILE_MODAL_TRANSITION
        }
      }}
    >
      {children}
    </Theme>
  );
};

export default CommutablyTheme;
