// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drDyx__forgotPassword {\n  position: relative;\n  top: -7.6em;\n  left: 0.5em;\n  font-size: 0.8em;\n}\n", "",{"version":3,"sources":["webpack://./src/app/Auth/LoginModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".forgotPassword {\n  position: relative;\n  top: -7.6em;\n  left: 0.5em;\n  font-size: 0.8em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotPassword": "drDyx__forgotPassword"
};
export default ___CSS_LOADER_EXPORT___;
