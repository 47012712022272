import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import React from 'react';

export const LogoIcon: React.FC<{ style?: React.CSSProperties; className?: string }> = ({
  style,
  className
}) => (
  <FontAwesomeIcon icon={faArrowRightArrowLeft} size="sm" style={style} className={className} />
);
