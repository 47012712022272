import React from 'react';

import { LogoIcon } from './LogoIcon.js';

import css from './Logo.module.css';
import { Link } from 'react-router-dom';

export const Logo: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <div className={css.logo} style={style}>
    <Link to="/">
      <div className={css.logoImgWrapper}>
        <LogoIcon className={css.img} />
        commutably
      </div>
      <small>life within reach</small>
    </Link>
  </div>
);
