import React from 'react';
import { useLocation } from 'react-router';
import { useTransition, animated } from '@react-spring/web';

import { useThemeAnimations } from '@wildidea/components/Theme/Theme';

import { usePrincipal } from '@app/Principal/usePrincipal.js';

import css from './TopNav.module.css';
import { LinkButton } from '@wildidea/components/Button/LinkButton';

export const TopNav: React.FC<{}> = () => {
  const principal = usePrincipal();
  const { pathname } = useLocation();

  return (
    <nav className={css.nav} aria-label="Main Menu">
      {!principal ? (
        <>
          <MapButton />
          <LinkButton
            to="/about"
            className={[css.pillButton, css.aboutButton, pathname === '/about' && css.active]
              .filter((x) => !!x)
              .join(' ')}
          >
            About
          </LinkButton>
          {/* <LinkButton
            to={{ search: `${search ? `${search}&` : '?'}login` }}
            role="button"
            level="tertiary"
            className={[css.pillButton, css.loginButton].join(' ')}
          >
            Log In
          </LinkButton> */}
        </>
      ) : (
        <>
          <MapButton />
        </>
      )}
    </nav>
  );
};

const MapButton: React.FC<{}> = () => {
  const { pathname } = useLocation();
  const { inPlace } = useThemeAnimations('Common');
  const showMapButton = pathname !== '/';
  const mapButtonTransition = useTransition(showMapButton, { ...inPlace, delay: 1500 });
  return mapButtonTransition((style, showMapButton) =>
    showMapButton ? (
      <animated.div style={style}>
        <LinkButton
          to="/"
          className={[css.pillButton, css.mapButton, pathname === '/' && css.active]
            .filter((x) => !!x)
            .join(' ')}
        >
          Map
        </LinkButton>
      </animated.div>
    ) : null
  );
};
