import React from 'react';
import { Navigate, Route } from 'react-router';

import { AnimatedRoute, AnimatedRoutes } from '@wildidea/components/AnimatedRoutes/AnimatedRoutes';
import { useBodyClass } from '@wildidea/components/hooks/useBodyClass';
import { useParameterDialogState } from '@wildidea/components/hooks/useHistoryBackedDialogState';
import { isTouchOnlyDevice } from '@wildidea/components/utils/isTouchOnlyDevice';

import { Logo } from '@components/Logo/Logo.js';

import { LoginModal } from './Auth/LoginModal.js';
import { TopNav } from './Navigation/TopNav.js';

const MapRoute = React.lazy(() => import('./routes/map/MapRoute.js' /** webpackPrefetch true */));
const GraphBuilderRoute = React.lazy(
  () => import('./routes/map/GraphBuilderRoute.js' /** webpackPrefetch true */)
);
const AboutRoute = React.lazy(
  () => import('./routes/about/AboutRoute.js' /** webpackPrefetch true */)
);
import OverlayViewport from './routes/OverlayViewport.js';

import './viewport.css';
import { useSearchParams } from 'react-router-dom';

export const App: React.FC<{}> = () => {
  useBodyClass(isTouchOnlyDevice() ? 'touch-only' : null);

  const [loginModalOpen, setLoginModalOpen] = useParameterDialogState('login');
  const [params] = useSearchParams();
  const defaultUsername = params.get('user');

  const hasVisitedBefore = window.localStorage.getItem(VISITED_BEFORE_STORAGE_KEY);

  React.useEffect(() => {
    window.localStorage.setItem(VISITED_BEFORE_STORAGE_KEY, 'true');
  }, []);

  return (
    <>
      <header className="header">
        <Logo />
        <TopNav />
      </header>

      <main>
        <AnimatedRoutes>
          <AnimatedRoute
            key="map-host"
            path="/"
            element={
              <React.Suspense>
                <MapRoute />
              </React.Suspense>
            }
          >
            <AnimatedRoute
              key="graph-builder"
              index
              element={
                <React.Suspense>
                  <GraphBuilderRoute />
                </React.Suspense>
              }
            />
            <AnimatedRoute key="overlay" element={<OverlayViewport />}>
              <AnimatedRoute
                key="about"
                path="/about"
                element={
                  <React.Suspense>
                    <AboutRoute />
                  </React.Suspense>
                }
              />
            </AnimatedRoute>
          </AnimatedRoute>

          <Route path="*" element={<Navigate to="/" replace />} />
        </AnimatedRoutes>
      </main>

      <LoginModal
        open={loginModalOpen}
        defaultUsername={defaultUsername || undefined}
        onClose={() => {
          setLoginModalOpen(false);
        }}
      />

      {!hasVisitedBefore ? <Navigate to="/about" /> : null}
    </>
  );
};

const VISITED_BEFORE_STORAGE_KEY = 'App/visitedBefore';
