// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HOJn4__overlay {\n  pointer-events: all;\n  padding: 3.4em max(0.5em, min(3vw, 2em)) 0;\n  height: 100%;\n  overflow: auto;\n  overscroll-behavior: none;\n}\n@media screen and (min-width: 768px) {\n  .HOJn4__overlay {\n    padding-top: 4.5em;\n}\n  }\n.HOJn4__overlay .ZSB79__backdrop {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  }\n.HOJn4__overlay .diDR3__content {\n    position: relative;\n    height: 100%;\n    pointer-events: none;\n  }\n.HOJn4__overlay .diDR3__content > * {\n      pointer-events: all;\n    }\n", "",{"version":3,"sources":["webpack://./src/app/routes/OverlayViewport.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,0CAA0C;EAC1C,YAAY;EACZ,cAAc;EACd,yBAAyB;AAuB3B;AArBE;EAPF;IAQI,kBAAkB;AAoBtB;EAnBE;AAEA;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;EACT;AAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;EAKtB;AAHE;MACE,mBAAmB;IACrB","sourcesContent":[".overlay {\n  pointer-events: all;\n  padding: 3.4em max(0.5em, min(3vw, 2em)) 0;\n  height: 100%;\n  overflow: auto;\n  overscroll-behavior: none;\n\n  @media (--screen-md) {\n    padding-top: 4.5em;\n  }\n\n  & .backdrop {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  }\n\n  & .content {\n    position: relative;\n    height: 100%;\n    pointer-events: none;\n\n    & > * {\n      pointer-events: all;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "HOJn4__overlay",
	"backdrop": "ZSB79__backdrop",
	"content": "diDR3__content"
};
export default ___CSS_LOADER_EXPORT___;
